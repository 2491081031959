@tailwind components;
@tailwind utilities;
@tailwind base;

@import "primeng/resources/primeng.css";

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ababab;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.debug {
  border: 2px solid red;
}

body,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span {
  font-family: "Inter", sans-serif;
}

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: revert;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: revert;
  font-weight: revert;
}

body {
  margin: 0;
  // background: var(--bg-white);
}

html,
body {
  // width: 100vw;
  // height: var(--dynamic-screen);
  // overflow: hidden;
}

.no-scroll {
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.scroll-padding {
  padding-right: 5px;

  &.scroll {
    padding-right: 5px;
  }
}

.scroll {
  &::-webkit-scrollbar {
    display: unset;
    width: 7px;
    margin-left: 10px !important;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
    margin-left: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #ababab;
    border-radius: 5px;
    margin-left: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

@layer base {
  :root {
    font-size: 14px;
    line-height: 1.6rem;
    --primary-lighter: #4db6ac;
    --primary-light: #4db6ac;
    --primary: #009688;
    --primary-DEFAULT: #009688;
    --primary-alternatve: #14b8a6;
    --primary-dark: #004d40;
    --primary-bg: #00968845;

    --secondary-light: #78909c;
    --secondary: #607d8b;
    --secondary-DEFAULT: #607d8b;
    --secondary-dark: #263238;

    --bg-lighter: #fcfcfd;
    --bg-light: #e1e2e345;
    --bg: #dedede;
    --bg-DEFAULT: #dedede;
    --bg-dark: #cccccc;
    --bg-darker: #c2c2c2;
    --bg-white: #ffffff;

    --border-prime: #ced4da;
    --border: #f2f4f7;
    --border-DEFAULT: #f2f4f7;
    --border-darker: #00000080;

    --prime-primary: #14b8a6;
    --prime-secondary: #64748b;
    --prime-success: #22c55e;
    --prime-info: #3b82f6;
    --prime-info-bg: #3b82f660;
    --prime-warning: #f59e0b;
    --prime-warning-bg: #f59e0b60;
    --prime-help: #a855f7;
    --prime-danger: #dc2626;
    --prime-danger-bg: #dc262660;

    --warn-light: #e57373;
    --warn: #f44336;
    --warn-DEFAULT: #f44336;
    --warn-dark: #b71c1c;

    --success-light: #aad576;
    --success: #245501;
    --success-DEFAULT: #245501;
    --success-dark: #143601;

    --info-light: #a6e1fa;
    --info: #0a2472;
    --info-DEFAULT: #0a2472;
    --info-dark: #00072d;

    --white: #ffffff;
    --black: #000000;

    --dynamic-screen: var(--dynamic-screen);

    @supports (height: 100dvh) {
      --dynamic-screen: 100dvh;
    }
  }
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .rotated {
    transform: rotateX(180deg);
  }

  .primeng-border {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #99f6e4;
    border-color: #14b8a6;
  }

  .hide-right-shadow {
    clip-path: inset(-5px 1px -5px -5px);
  }
  .hide-left-shadow {
    clip-path: inset(-5px -5px -5px 1px);
  }

  .p-button {
    &.p-button-icon-only {
      padding: 0;
      &.xsmall-button {
        height: 2rem !important;
        width: 2rem !important;
      }
      &.small-button {
        height: 2.5rem !important;
        width: 2.5rem !important;
      }
      &.normal-button {
        height: 3rem !important;
        width: 3rem !important;
      }
    }
  }

  .single-line {
    @apply text-ellipsis whitespace-nowrap overflow-hidden;
  }

  .bordered {
    @apply border border-solid border-border;
    &.right-side {
      @apply border-0 border-r;
    }
    &.bottom-side {
      @apply border-0 border-b;
    }
    &.top-side {
      @apply border-0 border-t;
    }
    &.left-side {
      @apply border-0 border-l;
    }
  }
}

p-button spinnericon {
  margin-left: 1rem;
}
p-dropdown .p-dropdown-label {
  line-height: 1.3rem !important;
}
span.p-image-preview-container {
  display: inline-flex;
}
.custom-spinner .p-progress-spinner-circle {
  @apply stroke-white;
  animation: custom-progress-spinner-dash 1.5s ease-in-out infinite, white 6s ease-in-out infinite;
}
.p-steps ul li {
  &:first-of-type::before {
    left: 50%;
  }
  &:last-of-type::before {
    left: -50%;
  }
}
.p-steps .p-steps-item .p-menuitem-link {
  background: transparent !important;
}

ckeditor {
  @apply w-full flex justify-center;
}
.ck .ck-editor__main {
  ul {
    @apply list-disc ml-10 mb-5;
  }
  ol {
    @apply list-decimal ml-10 mb-5;
  }
  p {
    @apply my-0;
  }
}
.ck.ck-editor {
  width: 100% !important;
}
.ck-content {
  @apply h-[500px] overflow-auto;
}
.editor-viewer {
  img {
    @apply w-full;
  }
  blockquote {
    @apply m-0 border-solid border-l-secondary border-0 border-l-4 px-8 py-4;
  }
  ul {
    @apply list-disc ml-10;
  }
  ol {
    @apply list-decimal ml-10;
  }
  p {
    @apply my-0;
  }
}
app-media-preview {
  position: relative;
}
.p-tabview-nav-container {
  @apply overflow-hidden;
}
.p-tabview-nav {
  @apply border-0 max-w-max #{!important};
}
.p-tabview-ink-bar {
  @apply border-solid border-b-primary-light border-0 border-b-2 hidden #{!important};
}
.p-tabview-panels {
  @apply overflow-y-visible #{!important};
  .p-tabview-panel {
    @apply h-full overflow-y-visible #{!important};
  }
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  @apply border-0 border-b-4 border-solid border-primary-light;
}
.p-dropdown-item span {
  @apply block text-ellipsis w-full whitespace-nowrap overflow-hidden;
}
.p-dropdown-label {
  @apply block text-ellipsis w-full whitespace-nowrap overflow-hidden;
}
.only-item-dialog {
  @apply w-screen h-screen;
}
.p-tabview {
  &.border-bottom {
    .p-tabview-nav-content {
      box-shadow: 0px -2px 0px 0px #dee2e6 inset;
      -webkit-box-shadow: 0px -2px 0px 0px #dee2e6 inset;
      -moz-box-shadow: 0px -2px 0px 0px #dee2e6 inset;
    }
  }
}

.panel-show.p-overlaypanel {
  @apply shadow-xl;
  &::before,
  &::after {
    display: none;
  }
  .p-overlaypanel-content {
    @apply p-0;
  }
}

.custom-date-panel.p-datepicker table td > span {
  height: unset;
  width: unset;
  border-radius: 0;
}

.p-tabview .p-tabview-nav {
  background: transparent !important;
  li .p-tabview-nav-link {
    background: transparent !important;
  }
}

.p-tabview-nav-content {
  margin-bottom: 1rem;
}

.card {
  .p-tabview .p-tabview-panels {
    background: transparent;
    padding: 0;
  }
}

.p-tabview .p-tabview-panels {
  padding: 0 !important;
}

.p-datatable .p-datatable-loading-overlay {
  background-color: rgba(150, 150, 150, 0.12);
}

.p-float-label textarea ~ label {
  top: 0 !important;
  @apply bg-white #{!important};
}

.p-tabview .p-tabview-nav li.extra a {
  @apply p-0;
}

.wrap-steps {
  &.p-steps ul {
    width: 100%;
    flex-wrap: wrap;
  }
  &.p-steps-item {
    padding: 0 10px;
  }
}

.phone {
  &::-webkit-scrollbar {
    width: 2px;
    margin-left: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
    margin-left: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #ababab;
    border-radius: 5px;
    margin-left: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.focused {
  border: 1px dashed #6acce0;
}

.errored-image-p-image {
  width: 100%;
  display: block;
  span {
    display: block !important;
  }
}

.custom-tooltip {
  position: absolute;
  text-align: center;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  z-index: 9999;
  display: none;
}

.custom-tooltip.show {
  display: block;
}

// Animations

.shake {
  animation: shake 1s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.bounce {
  animation: bounce 1s ease infinite;
}
@keyframes bounce {
  70% {
    transform: translateY(0%) scale(1.5);
  }
  80% {
    transform: translateY(-30%);
  }
  90% {
    transform: translateY(10%);
  }
  95% {
    transform: translateY(-7%);
  }
  97% {
    transform: translateY(0%);
  }
  99% {
    transform: translateY(-3%);
  }
  100% {
    transform: translateY(0);
  }
}
